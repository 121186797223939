export enum LocationType {
    Hand = 1,
    PlayArea,
    Deck,
    PlayerColumns,
    AllianceCard,
    BetrayalCard,
    PlayerCard,
    CampToken
}
