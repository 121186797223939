import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { AVATAR_INFO } from '../api'
import { User } from './User'

const GET_ME = gql`query Me { me { user { id name subscriptionSince avatar {...AvatarInfo} } } } ${AVATAR_INFO}`

export function useMe() {
  const urlSearchParams = new URLSearchParams(window.location.search)
  const fetchPolicy = useMemo(() =>
      process.env.NODE_ENV !== 'production' && !urlSearchParams.get('game') && !urlSearchParams.get('mode') ? 'cache-only' : 'cache-first'
    , [])
  return useQuery<{ me: { user: User } }>(GET_ME, { ssr: false, fetchPolicy }).data?.me
}
